import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';
import ElementUI from 'element-ui';
import VueCookies from 'vue-cookies';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2';
import { Notification } from 'element-ui';
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$notify = Notification;
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.use(VueCookies);
// axios.defaults.baseURL = "/debug"
axios.defaults.baseURL = "https://live.gaoweimap.com"
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
