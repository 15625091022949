import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/Login.vue'
import deviceList from '../views/wvpVideo/DeviceList.vue'
import channelList from '../views/wvpVideo/channelList.vue'
import wasmPlayer from '../views/wvpVideo/common/jessibuca.vue'
import live from '../views/wvpVideo/live.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/wvpVideo/deviceList'
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: login,
  },
  {
    path: '/wvpVideo/deviceList',
    component: deviceList,
  },
  {
    path: '/wvpVideo/channelList/:deviceId/:parentChannelId/',
    name: 'channelList',
    component: channelList,
  },
  {
    path: '/wvpVideo/play/wasm/:url',
    name: 'wasmPlayer',
    component: wasmPlayer,
  },
  {
    path: '/wvpVideo/live',
    component: live,
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
